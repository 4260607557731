import { clsx, type ClassValue } from "clsx";
import { XMLBuilder, XMLParser } from "fast-xml-parser";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getMaxSize(svg: string): { width: number; height: number } {
  interface SVGRect {
    "@_width": string | number;
    "@_height": string | number;
    [key: string]: unknown;
  }

  interface SVGObject {
    rect?: SVGRect | SVGRect[];
    [key: string]: unknown;
  }
  const parser = new XMLParser({
    ignoreAttributes: false,
    parseAttributeValue: true,
  });
  const parsed = parser.parse(svg);

  // Find all rectangles in the SVG
  const getAllRects = (obj: SVGObject | SVGObject[]): SVGRect[] => {
    let rects: SVGRect[] = [];
    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        if (item["@_maskUnits"]) {
          return [];
        }
        if (item.rect) {
          rects = rects.concat(
            Array.isArray(item.rect) ? item.rect : [item.rect]
          );
        }
        Object.values(item).forEach((value) => {
          if (typeof value === "object" && value !== null) {
            rects = rects.concat(getAllRects(value as SVGObject));
          }
        });
      });
    } else if (typeof obj === "object") {
      if (obj.rect) {
        rects = rects.concat(Array.isArray(obj.rect) ? obj.rect : [obj.rect]);
      }
      Object.values(obj).forEach((value) => {
        if (typeof value === "object" && value !== null) {
          rects = rects.concat(getAllRects(value as SVGObject));
        }
      });
    }
    return rects;
  };

  const allRects = getAllRects(parsed.svg);

  // Calculate maximum dimensions
  const maxWidth = Math.max(
    ...allRects.map((rect: SVGRect) =>
      parseFloat(rect["@_width"].toString() || "0")
    )
  );
  const maxHeight = Math.max(
    ...allRects.map((rect: SVGRect) =>
      parseFloat(rect["@_height"].toString() || "0")
    )
  );
  return { width: maxWidth, height: maxHeight };
}

export function autoResizeSVG(svg: string): string {
  interface SVGRect {
    "@_width": string | number;
    "@_height": string | number;
    [key: string]: unknown;
  }

  interface SVGObject {
    rect?: SVGRect | SVGRect[];
    [key: string]: unknown;
  }
  const parser = new XMLParser({
    ignoreAttributes: false,
    parseAttributeValue: true,
  });
  const parsed = parser.parse(svg);

  // Find all rectangles in the SVG
  const getAllRects = (obj: SVGObject | SVGObject[]): SVGRect[] => {
    let rects: SVGRect[] = [];
    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        if (item["@_maskUnits"]) {
          return [];
        }
        if (item.rect) {
          rects = rects.concat(
            Array.isArray(item.rect) ? item.rect : [item.rect]
          );
        }
        Object.values(item).forEach((value) => {
          if (typeof value === "object" && value !== null) {
            rects = rects.concat(getAllRects(value as SVGObject));
          }
        });
      });
    } else if (typeof obj === "object") {
      if (obj.rect) {
        rects = rects.concat(Array.isArray(obj.rect) ? obj.rect : [obj.rect]);
      }
      Object.values(obj).forEach((value) => {
        if (typeof value === "object" && value !== null) {
          rects = rects.concat(getAllRects(value as SVGObject));
        }
      });
    }
    return rects;
  };

  const allRects = getAllRects(parsed.svg);

  // Calculate maximum dimensions
  const maxWidth = Math.max(
    ...allRects.map((rect: SVGRect) =>
      parseFloat(rect["@_width"].toString() || "0")
    )
  );
  const maxHeight = Math.max(
    ...allRects.map((rect: SVGRect) =>
      parseFloat(rect["@_height"].toString() || "0")
    )
  );

  // Update SVG dimensions
  parsed.svg["@_width"] = maxWidth;
  parsed.svg["@_height"] = maxHeight;
  parsed.svg["@_viewBox"] = `0 0 ${maxWidth} ${maxHeight}`;

  const builder = new XMLBuilder({
    ignoreAttributes: false,
  });
  return builder.build(parsed);
}

import { create } from "zustand";

interface SatoriFontsState {
  font: ArrayBuffer;
}

export const useSatoriFontsStore = create<SatoriFontsState>()((set) => {
  const initialState = {
    font: new ArrayBuffer(0),
    setFont: (font: ArrayBuffer) => set(() => ({ font })),
  };

  fetch("/fonts/LXGWBrightCodeTC-Regular.ttf")
    .then((res) => res.arrayBuffer())
    .then((font) => initialState.setFont(font))
    .catch(console.error);

  return initialState;
});

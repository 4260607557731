import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import { loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";
import jsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker";
import cssWorker from "monaco-editor/esm/vs/language/css/css.worker?worker";
import htmlWorker from "monaco-editor/esm/vs/language/html/html.worker?worker";
import tsWorker from "monaco-editor/esm/vs/language/typescript/ts.worker?worker";
import tailwindWorker from "@/lib/monaco-tailwindcss/tailwindcss.worker?worker";
import { configureMonacoTailwindcss } from "@/lib/monaco-tailwindcss/index";

window.MonacoEnvironment = {
  getWorker(_, label) {
    switch (label) {
      case "css":
      case "less":
      case "scss":
        return new cssWorker();
      case "handlebars":
      case "html":
      case "razor":
        return new htmlWorker();
      case "json":
        return new jsonWorker();
      case "javascript":
      case "typescript":
        return new tsWorker();
      case "tailwindcss":
        return new tailwindWorker();
      default:
        return new editorWorker();
    }
  },
};

configureMonacoTailwindcss(monaco, {});
loader.config({ monaco });

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);

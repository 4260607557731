"use client";

import { useEffect, useRef, useState } from "react";
import { useSatoriFontsStore } from "@/lib/store/satori";
import satori, { SatoriOptions } from "satori";
import MonacoEditor from "@monaco-editor/react";
import { LiveProvider, withLive } from "react-live";
import { Slider } from "@/components/ui/slider";
import { Switch } from "@/components/ui/switch";
import { getMaxSize } from "@/lib/utils";

export default function Page() {
  const [code, setCode] = useState(() => {
    const savedCode = localStorage.getItem("satori-code");
    return (
      savedCode ??
      `<div tw="flex flex-col">
  <div tw='text-2xl border rounded-lg'>Hello World</div>
</div>`
    );
  });
  const { font } = useSatoriFontsStore();
  const [height, setHeight] = useState(400);
  const [width, setWidth] = useState(600);

  const [options, setOptions] = useState<SatoriOptions>({
    width,
    height,
    debug: true,
    fonts: [
      {
        name: "LXGWBrightCodeTC",
        data: font,
      },
    ],
  });
  const [autoResize, setAutoResize] = useState(false);
  useEffect(() => {
    if ("width" in options) {
      setWidth(options.width);
    }
    if ("height" in options) {
      setHeight(options.height);
    }
  }, [options]);
  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      fonts: [
        {
          name: "LXGWBrightCodeTC",
          data: font,
        },
      ],
    }));
  }, [font]);

  useEffect(() => {
    localStorage.setItem("satori-code", code);
  }, [code]);

  return (
    <main className="flex h-screen bg-zinc-900">
      <div className="w-1/2 border-r border-zinc-700 p-4">
        <div className="flex flex-col h-full gap-4">
          <div className="group flex-1">
            <div className="flex flex-col w-full h-full p-4 bg-zinc-800 border border-zinc-700 rounded-lg group-focus-within:ring-2 group-focus-within:ring-zinc-600">
              <MonacoEditor
                className="w-full h-full"
                language="html"
                theme="vs-dark"
                value={code}
                onChange={(value) => setCode(value ?? "")}
                options={{
                  fontSize: 14,
                  wordWrap: "on",
                  tabSize: 2,
                  minimap: {
                    enabled: false,
                  },
                  smoothScrolling: true,
                  cursorSmoothCaretAnimation: "on",
                  automaticLayout: true,
                  scrollBeyondLastLine: false,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-1/2 flex flex-col border-r border-zinc-700 p-4">
        <LiveProvider code={code}>
          <LiveStora options={options} autoResize={autoResize} />
        </LiveProvider>

        <div className="border-zinc-700 p-4 bg-zinc-900">
          <h2 className="text-xl font-semibold text-zinc-200 mb-4">
            Configuration
          </h2>
          <div className="flex flex-col gap-2">
            <SliderInput
              label="Container Height"
              value={height}
              onChange={(value) =>
                setOptions((prev) => ({ ...prev, height: value }))
              }
            />
            <SliderInput
              label="Container Width"
              value={width}
              onChange={(value) =>
                setOptions((prev) => ({ ...prev, width: value }))
              }
            />
            <div className="flex gap-4 items-center">
              <div className="text-sm font-medium text-zinc-400">Debug</div>
              <Switch
                checked={options.debug}
                onCheckedChange={(checked) =>
                  setOptions((prev) => ({ ...prev, debug: checked }))
                }
              />
              <div className="ml-8 text-sm font-medium text-zinc-400">
                AutoResize
              </div>
              <Switch
                checked={autoResize}
                onCheckedChange={(checked) => setAutoResize(checked)}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const SliderInput = ({
  label,
  value,
  min = 1,
  max = 1920,
  onChange,
}: {
  label: string;
  value: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
}) => {
  return (
    <div className="flex gap-4 items-center">
      <div className="text-sm font-medium text-zinc-400 w-32">{label}</div>
      <Slider
        className="w-48"
        min={min}
        max={max}
        value={[value]}
        onValueChange={(value) => onChange(value[0])}
      />
      <input
        type="text"
        value={value}
        className="w-16 px-2 py-1 text-sm text-zinc-200 bg-zinc-800 border border-zinc-700 
                    rounded-md focus:outline-none focus:ring-2 focus:ring-zinc-600"
        onChange={(e) =>
          onChange(Math.max(min, Math.min(max, Number(e.target.value))))
        }
      />
      <span className="text-sm text-zinc-400">px</span>
    </div>
  );
};

const LiveStora = withLive(function ({
  live,
  options,
  autoResize = true,
}: {
  live?: { element: React.ComponentType; error: string };
  options: SatoriOptions;
  autoResize?: boolean;
}) {
  const [svg, setSvg] = useState<string | null>(null);
  const svgContainerRef = useRef<HTMLDivElement>(null);
  const svgBorderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (live?.element && options.fonts[0].data.byteLength > 0) {
      satori(live.element.prototype.render(), options)
        .then((svg) => {
          setSvg(svg);

          if (autoResize) {
            const { width, height } = getMaxSize(svg);
            svgBorderRef.current?.style.setProperty("width", `${width + 4}px`);
            svgBorderRef.current?.style.setProperty(
              "height",
              `${height + 4}px`
            );
          } else {
            svgBorderRef.current?.style.setProperty("width", "100%");
            svgBorderRef.current?.style.setProperty("height", "100%");
          }
        })
        .catch((err) => {
          console.error(err);
          setSvg(err.message);
        });
    }
  }, [live, options, autoResize]);

  return (
    <div className="flex-1 p-4 bg-zinc-800 rounded-lg">
      <div className="relative w-full h-full flex flex-col overflow-scroll">
        {svg ? (
          <>
            <div className="absolute flex">
              <div
                ref={svgContainerRef}
                className="flex items-center justify-center border-2 border-transparent"
                dangerouslySetInnerHTML={{ __html: svg }}
              />
              <div
                ref={svgBorderRef}
                className="absolute border-2 border-zinc-500 w-full h-full"
              ></div>
            </div>
          </>
        ) : (
          <div className="text-zinc-500">SVG预览区域</div>
        )}
      </div>
    </div>
  );
});
